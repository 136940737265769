import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import SplitType from "split-type";

const desctop = window.innerWidth > 1200;

function animationDesctop() {
  function From(h) {
    return (s, p) => gsap.from(`${h} ${s}`, p);
  }
  function FromTo(h) {
    return (s, f, t) => gsap.fromTo(`${h} ${s}`, f, t);
  }
  function Split(h, p = {}) {
    return (s) => {
      const split = new SplitType(`${h} ${s}`, {
        types: (window.innerWidth > 1200 && "lines chars") || "lines words",
        tagName: "span",
        ...p,
      });
    };
  }

  ((h) => {
    const from = From(h);
    const split = Split(h);
    from(".custom-logo-link", {
      opacity: 0,
      y: -50,
      duration: 1,
    });
    split(".menu-item");

    from(".menu-item .char", {
      opacity: 0.1,
      duration: 0.4,
      ease: "expo.out",
      stagger: 0.05,
    });
  })("header");

  ((h) => {
    const from = From(h);
    const fromTo = (s, f, t) => gsap.fromTo(`${h} ${s}`, f, t);
    const split = Split(h);
    [
      ".hero-test-description",
      ".hero-system-title p",
      ".hero-test-title",
      ".hero-test-text p",
      ".hero-info-text",
    ].forEach((s) => split(s));
    from(".hero-system", {
      opacity: 0,
      y: -30,
      x: 30,
      duration: 0.8,
      delay: 0.2,
      backgroundSize: "10px 10px",
    });
    from(".hero-system-title .char", {
      opacity: 0,
      y: 10,
      rotate: 5,
      duration: 0.3,
      ease: "expo.out",
      stagger: 0.05,
    });
    from(".hero-test", {
      opacity: 0,
      x: 30,
      duration: 0.8,
      delay: 0.2,
    });
    from(".hero-test-title .char", {
      opacity: 0,
      y: 10,
      x: -10,
      rotate: 5,
      duration: 0.5,
      delay: 0.2,
      ease: "expo.out",
      stagger: 0.05,
    });
    fromTo(
      ".hero-test-image img",
      {
        clipPath: "circle(0% at 82% 16%))",
        opacity: 0,
      },
      {
        duration: 1.6,
        opacity: 1,
        delay: 1,
        clipPath: "circle(130% at 82% 16%)",
      }
    );
    from(".hero-test-text .char", {
      opacity: 0,
      y: 5,
      x: -2,
      rotate: 1,
      duration: 0.3,
      delay: 0.1,
      ease: "expo.out",
      stagger: 0.03,
    });
    from(".hero-test-description .line", {
      y: -15,
      x: -5,
      opacity: 0,
      duration: 0.8,
      ease: "expo.out",
      stagger: 0.08,
      delay: 1.3,
    });
    from(".hero-info", {
      opacity: 0,
      y: 30,
      x: 30,
      duration: 0.8,
      delay: 0.2,
    });
    from(".hero-info .hero-info-image", {
      opacity: 0,
      y: -20,
      duration: 0.8,
      delay: 0.5,
    });
    from(".hero-info-text .char", {
      opacity: 0.3,
      duration: 0.2,
      stagger: 0.04,
    });
    from(".hero-info a", {
      opacity: 0,
      y: 20,
      duration: 0.9,
      delay: 0.5,
    });
    from(".hero-title, .hero-subtitle", {
      opacity: 0,
      y: 20,
      duration: 0.9,
      delay: 0.3,
      stagger: 0.1,
    });
    from(".hero-btn-wrapp .request-btn, .hero-tel-wrapp", {
      opacity: 0,
      y: 20,
      x: -20,
      duration: 0.9,
      delay: 0.5,
    });
  })("#hero");

  ((a) => {
    const split = Split(a);
    const from = From(a);
    [".about-item-text p", ".about-item-title"].forEach((s) => split(s));
    from(".about-item:nth-child(1) .about-item-logo", {
      opacity: 0,
      y: 20,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(1)`,
        start: "top 70%",
      },
    });
    from(".about-item:nth-child(1) .about-item-thumb", {
      opacity: 0,
      y: 20,
      x: 30,
      duration: 1,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(1)`,
        start: "top 70%",
      },
    });
    from(".about-item:nth-child(1) .about-item-text p .line", {
      opacity: 0,
      x: -25,
      y: 30,
      stagger: 0.07,
      duration: 0.8,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(1)`,
        start: "top 60%",
      },
    });
    from(".about-item:nth-child(2) .about-item-thumb", {
      opacity: 0,
      y: 20,
      x: 30,
      duration: 1,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(2)`,
        start: "top 70%",
      },
    });
    from(".about-item:nth-child(2) .about-item-text p .line", {
      opacity: 0,
      x: -25,
      y: 30,
      stagger: 0.07,
      duration: 0.8,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(2)`,
        start: "top 60%",
      },
    });
    from(".about-item:nth-child(2) .about-item-title .char", {
      opacity: 0,
      y: 10,
      duration: 0.1,
      stagger: 0.06,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(2)`,
        start: "top 70%",
      },
    });
    from(".about-item:nth-child(2) .about-item-subtitle", {
      opacity: 0,
      y: 20,
      duration: 0.9,
      scrollTrigger: {
        trigger: `${a} .about-item:nth-child(2)`,
        start: "top 70%",
      },
    });
  })("#about");

  // ((w) => {
  //   const split = Split(w);
  //   const fromTo = FromTo(w);
  //   const from = From(w);
  //   [".why-title h2"].forEach((s) => split(s));
  //   const trigger = function (s) {
  //     return {
  //       trigger: `${w} ${s}`,
  //       start: "top 80%",
  //     };
  //   };

  //   from(`.why-title h2 .char`, {
  //     opacity: 0,
  //     stagger: 0.05,
  //     scrollTrigger: trigger(".why-title h2"),
  //   });
  //   fromTo(
  //     ".why-box-pacient .why-item",
  //     { y: -20, opacity: 0 },
  //     { y: 0, opacity: 1, duration: 1, stagger: 0.35 },
  //     1.3
  //   );
  //   fromTo(
  //     ".why-box-doctor .why-item",
  //     { y: -20, opacity: 0 },
  //     { y: 0, opacity: 1, duration: 1, stagger: 0.35 },
  //     1.3
  //   );
  //   // fromTo(
  //   //   `.why-box-pacient .why-item:nth-child(1)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "241% 568%",
  //   //     transform: "rotate(-11deg)",
  //   //   },
  //   //   {
  //   //     transform: "rotate(0deg)",
  //   //     opacity: 1,
  //   //     scrollTrigger: trigger(".why-box-pacient .why-item:nth-child(1)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-doctor .why-item:nth-child(1)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "-108% 413%",
  //   //     transform: "rotate(11deg)",
  //   //   },
  //   //   {
  //   //     opacity: 1,
  //   //     transform: "rotate(0deg)",
  //   //     scrollTrigger: trigger(".why-box-doctor .why-item:nth-child(1)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-pacient .why-item:nth-child(2)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "241% 68%",
  //   //     transform: "rotate(-11deg)",
  //   //   },
  //   //   {
  //   //     transform: "rotate(0deg)",
  //   //     opacity: 1,
  //   //     scrollTrigger: trigger(".why-box-pacient .why-item:nth-child(2)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-doctor .why-item:nth-child(2)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "-128% 73%",
  //   //     transform: "rotate(11deg)",
  //   //   },
  //   //   {
  //   //     opacity: 1,
  //   //     transform: "rotate(0deg)",
  //   //     scrollTrigger: trigger(".why-box-doctor .why-item:nth-child(2)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-pacient .why-item:nth-child(3)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "241% 68%",
  //   //     transform: "rotate(-11deg)",
  //   //   },
  //   //   {
  //   //     transform: "rotate(0deg)",
  //   //     opacity: 1,
  //   //     scrollTrigger: trigger(".why-box-pacient .why-item:nth-child(3)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-doctor .why-item:nth-child(3)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "-128% 73%",
  //   //     transform: "rotate(11deg)",
  //   //   },
  //   //   {
  //   //     opacity: 1,
  //   //     transform: "rotate(0deg)",
  //   //     scrollTrigger: trigger(".why-box-doctor .why-item:nth-child(3)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-pacient .why-item:nth-child(4)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "241% 68%",
  //   //     transform: "rotate(-11deg)",
  //   //   },
  //   //   {
  //   //     transform: "rotate(0deg)",
  //   //     opacity: 1,
  //   //     scrollTrigger: trigger(".why-box-pacient .why-item:nth-child(4)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-doctor .why-item:nth-child(4)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "-128% 73%",
  //   //     transform: "rotate(11deg)",
  //   //   },
  //   //   {
  //   //     opacity: 1,
  //   //     transform: "rotate(0deg)",
  //   //     scrollTrigger: trigger(".why-box-doctor .why-item:nth-child(4)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-pacient .why-item:nth-child(5)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "241% 68%",
  //   //     transform: "rotate(-11deg)",
  //   //   },
  //   //   {
  //   //     transform: "rotate(0deg)",
  //   //     opacity: 1,
  //   //     scrollTrigger: trigger(".why-box-pacient .why-item:nth-child(5)"),
  //   //   }
  //   // );
  //   // fromTo(
  //   //   `.why-box-doctor .why-item:nth-child(5)`,
  //   //   {
  //   //     opacity: 0,
  //   //     transformOrigin: "-128% 73%",
  //   //     transform: "rotate(11deg)",
  //   //   },
  //   //   {
  //   //     opacity: 1,
  //   //     transform: "rotate(0deg)",
  //   //     scrollTrigger: trigger(".why-box-doctor .why-item:nth-child(5)"),
  //   //   }
  //   // );
  // })("#why");

  ((w) => {
    const fromTo = FromTo(w);

    // Отримуємо сі елементи зі списків
    const pacientItems = document.querySelectorAll(
      `${w} .why-box-pacient .why-item`
    );
    const doctorItems = document.querySelectorAll(
      `${w} .why-box-doctor .why-item`
    );

    // Перетворюєм NodeList у масиви
    const pacientArray = Array.from(pacientItems);
    const doctorArray = Array.from(doctorItems);

    // Знаходимо максимальну довжину між списками
    const maxLength = Math.max(pacientArray.length, doctorArray.length);

    // ніціалізуємо timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: `${w} .why-title`,
        start: "top 60%",
      },
    });

    // Додаємо елемент до анімації по чрзі
    for (let i = 0; i < maxLength; i++) {
      if (pacientArray[i]) {
        tl.fromTo(
          pacientArray[i],
          { y: -20, opacity: 0 },
          { y: 0, opacity: 1, duration: 0.3, delay: i === 0 && 0.3 },
          "-=0.2"
        );
      }
      if (doctorArray[i]) {
        tl.fromTo(
          doctorArray[i],
          { y: -20, opacity: 0 },
          { y: 0, opacity: 1, duration: 0.3 },
          "-=0.2"
        );
      }
    }
  })("#why");

  ((a) => {
    const from = From(a);

    from(".analysis-title h2", {
      opacity: 0,
      y: 20,
      duration: 0.9,
      stagger: 0.1,
      scrollTrigger: {
        trigger: `${a} .analysis-title h2`,
        start: "top 60%",
      },
    });
    from(".analysis-table-item", {
      opacity: 0,
      y: 20,
      duration: 0.9,
      stagger: 0.1,
      scrollTrigger: {
        trigger: `${a} .analysis-table-item`,
        start: "top 60%",
      },
    });
  })("#analysis");

  ((a) => {
    const from = From(a);
    const fromTo = FromTo(a);
    const split = Split(a);
    [".advantages-item-text"].forEach((s) => split(s));
    from(".advantages-title", {
      opacity: 0,
      y: 20,
      duration: 0.9,
      stagger: 0.1,
      scrollTrigger: {
        trigger: `${a} .advantages-title`,
        start: "top 60%",
      },
    });

    // fromTo(
    //   ".advantages-item .advantages-item-thumb",
    //   {
    //     opacity: 0,
    //     x: -10,
    //     y: 10,
    //   },
    //   {
    //     opacity: 1,
    //     duration: 0.8,
    //     x: 0,
    //     y: 0,
    //     delay: 0.2,
    //     stagger: 0.1,
    //     scrollTrigger: {
    //       trigger: `${a} .advantages-item`,
    //       start: "top 60%",
    //     },
    //   }
    // );

    from(".advantages-item-text .line", {
      opacity: 0,
      y: 20,
      duration: 0.8,
      stagger: 0.05,
      scrollTrigger: {
        trigger: `${a} .advantages-item-text`,
        start: "top 60%",
      },
    });
  })("#advantages");

  ((r) => {
    const from = From(r);
    const split = Split(r, { types: "words" });
    [".review-title"].forEach((s) => split(s));
    from(".review-title .word", {
      opacity: 0.1,
      y: 5,
      duration: 0.8,
      ease: "expo.out",
      stagger: 0.04,
      delay: 0.4,
      scrollTrigger: {
        trigger: `${r} .review-title`,
        start: "top 70%",
      },
    });
  })("#review");
}

function animationMobile() {
  const tl = gsap.timeline();
  //Hero
  tl.fromTo(
    "#hero .hero-title",
    {
      y: -50,
      opacity: 0,
    },
    { y: 0, opacity: 1, duration: 1 },
    0.3
  )
    .fromTo(
      "#hero .hero-subtitle",
      {
        x: -50,
        opacity: 0,
      },
      { x: 0, opacity: 1, duration: 1 },
      0.6
    )
    .fromTo(
      "#hero .request-btn",
      { x: -50, opacity: 0 },
      { x: 0, opacity: 1, duration: 1 },
      0.8
    )
    .fromTo(
      "#hero .hero-tel-wrapp",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1, duration: 1 },
      1
    );

  //ScrollTrigger animations
  // gsap.from(".hero-system", {
  //   scrollTrigger: {
  //     trigger: ".hero-content-sm",
  //     start: "top 60%", // Починається анімація, коли верх елемента .hero-content-sm досягає верху екрану
  //   },
  //   x: "-100%",
  //   opacity: 0,
  //   duration: 1,
  // });
  // gsap.from("#hero .hero-test", {
  //   scrollTrigger: {
  //     trigger: "#hero .hero-content-sm",
  //     start: "top 65%", // Починається анімація, коли верх елемента .hero-content-sm досягає верху екрану
  //   },
  //   x: "100%",
  //   opacity: 0,
  //   duration: 1,
  //   delay: 0.2,
  // });
  // gsap.from("#hero .hero-info", {
  //   scrollTrigger: {
  //     trigger: "#hero .hero-content-sm",
  //     start: "top 70%", // Починається анімація, коли верх елемента .hero-content-sm досягає верху екрану
  //   },
  //   x: "-100%",
  //   opacity: 0,
  //   duration: 1,
  //   delay: 0.4,
  // });

  // // About
  // gsap.utils.toArray(".about-item").forEach((item, index) => {
  //   const direction = index % 2 === 0 ? "-100%" : "100%"; // Чередування напрямків (зліва направо, справа наліво)

  //   gsap.from(item, {
  //     scrollTrigger: {
  //       trigger: item,
  //       start: "top 80%", // Анімація починається, коли верх елемента входить у видиму частину екрану
  //       end: "top 20%", // Анімація закінчується, коли верх елемента виходить з видимої частини екрану

  //       toggleActions: "play none none none", // Анімація запускається тільки один раз, коли елемент входить в видимість
  //       markers: false, // Вимкнено для відладки
  //     },
  //     x: direction, // Напрямок анімації змінюється в залежності від індексу
  //     opacity: 0, // Початкова непрозорість
  //     duration: 0.5, // Тривалість анімації
  //     delay: index * 0.2, // Затримка для кожного елемента
  //     ease: "power2.out", // Плавне завершення анімації
  //   });
  // });

  // //Why

  // gsap.from(".why-title", {
  //   scrollTrigger: {
  //     trigger: ".why-wrapp",
  //     start: "top 80%",
  //   },
  //   y: -50,
  //   opacity: 0,
  //   duration: 0.5,
  // });
  // gsap.from(".why-box-pacient", {
  //   scrollTrigger: {
  //     trigger: ".why-box-pacient",
  //     start: "top 70%",
  //   },
  //   x: "-100%",
  //   opacity: 0,
  //   duration: 0.5,
  // });
  // gsap.from(".why-box-doctor", {
  //   scrollTrigger: {
  //     trigger: ".why-box-doctor",
  //     start: "top 70%",
  //   },
  //   x: "100%",
  //   opacity: 0,
  //   duration: 0.5,
  // });

  // //Test

  // gsap.from("#test .test-title", {
  //   scrollTrigger: {
  //     trigger: "#test ",
  //     start: "top 80%",
  //   },
  //   y: -50,
  //   opacity: 0,
  //   duration: 1,
  // });
  // gsap.from("#test .test-text", {
  //   scrollTrigger: {
  //     trigger: "#test ",
  //     start: "top 80%",
  //   },
  //   x: -50,
  //   opacity: 0,
  //   duration: 1,
  //   delay: 0.3,
  // });
  // gsap.from("#test .request-btn", {
  //   scrollTrigger: {
  //     trigger: "#test ",
  //     start: "top 80%",
  //   },
  //   y: 50,
  //   opacity: 0,
  //   duration: 1,
  // });
  // gsap.from("#test .test-info-wrapp", {
  //   scrollTrigger: {
  //     trigger: "#test .test-info",
  //     start: "top 80%",
  //   },
  //   x: "-100%",
  //   opacity: 0,
  //   duration: 1,
  // });
  // gsap.from("#test .test-link", {
  //   scrollTrigger: {
  //     trigger: "#test .test-info",
  //     start: "top 80%",
  //   },
  //   x: "100%",
  //   opacity: 0,
  //   duration: 1,
  // });
}
if (desctop) {
  animationDesctop();
} else {
  animationMobile();
}
