document.addEventListener("DOMContentLoaded", function () {
  const playButton = document.querySelector(".review-btn"); // Знаходимо кнопку
  const iframe = document.querySelector(".review-video iframe"); // Знаходимо iframe

  playButton.addEventListener("click", function () {
    const src = iframe.getAttribute("src"); // Отримуємо поточний src відео
    if (!src.includes("autoplay=1")) {
      iframe.setAttribute(
        "src",
        src + (src.includes("?") ? "&" : "?") + "autoplay=1"
      );
    }
    playButton.style.display = "none"; // Ховаємо кнопку після кліку
  });
});
