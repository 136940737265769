// import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";

import "./swiper";
import "./popup";
import "./accordeon";
import "./mask";
import "./formMask";
import "./formSubmit";
import "./closeBtn";
import "./video";
import "./animations";

import "../css/main.scss";
