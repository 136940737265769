const btn = document.querySelector(".close-button");
const links = document.querySelectorAll(".burger-menu a");
const backdrop = document.querySelector(".backdrop.mobile-burger ");

btn.addEventListener("click", () => {
  backdrop.classList.remove("show");
});

links.forEach((link) => {
  link.addEventListener("click", () => {
    backdrop.classList.remove("show");
  });
});
