import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const analysiSwiper = new Swiper(".analysis-slider", {
  slidesPerView: 1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 20,
      slidesPerView: 3,
    },
  },
  navigation: {
    prevEl: ".analysis-prev",
    nextEl: ".analysis-next",
  },
  autoHeight: true,
});
